const ja = {
  logistics: {
    login: '测试登录语言',
    search: '検索',
    reset: 'リセット',
    operation: '操作',
    UnderHeavyLoad: '必死ロード中',
    is: 'は',
    no: 'いいえ',
    save: '保存',
    load: 'ダウンロード',
    downloadLog: 'ダウンロードログ',
    cancel: 'キャンセル',
    editor: '編集',
    delete: '削除',
    add: '添加',
    new: '新たに',
    check: 'チェック',
    kicking: '蹴り人',
    generate: '生成',
    permissions: '権限',
    MenuPermissions: 'メニュー権限',
    DepartmentPermission: '部門の権限',
    update: '更新',
    DeletePrompt: '削除を確定しますか?',
    CanceledDelete: 'は削除',
    msg: '情報',
    determine: '確定',
    index: 'トップページ',
    PersonalInformation: '個人情報',
    LogOut: 'システムを脱退する',
    prompt: '提示',
    exitSystem: 'システムを脱退するか、継続するか?',
    more: 'より',
    CloseOther: '他',
    CloseAll: '全てを閉じる',
    FullScreen: '全般',
    ExitFullScreen: '全スクリーンから退出する',
    returnHome: 'トップページに戻る',
    LOOKTG: '前のページに戻る',
    NoMenu: 'メニューが見つからない',
    BeginRepair: '点検を始める',
    RepairComplete: '点検完了',
    put: '二次元コードを投入する',
    equipmentNumber: '設備番号',
    StartDate: '開始日時',
    EndDate: '終了期日',
    ExportCard: '導出カード',
    ImportmembershipCard: '会員カード情報の導入',
    activated: 'すでに活性化',
    binding: 'バインディング済み',
    failure: '失効した',
    preview: 'プレビュー',
    export: 'エクスポート',
    effective: '有効',
    lock: 'ロックオン',
    btn: 'ボタン',
    menu: 'メニュー',
    deleteSuccess: '削除成功!',
    addSuccess: '追加成功!',
    editSuccess: '編集成功!',
    success: '成功',
    accessLogin: 'ログイン成功'
  }
}

export default ja
