const cn = {
  // 用户登录
  login: {
    login: '用户登录',
    userEmail: '请输入用户名/邮箱/手机号',
    ru: '记住用户名',
    fr: '忘记密码',
    Login: '登录',
    qt: '其它登录方式',
    zc: '注册',
    hasAcc: '已有账户, 直接',
    passWord: '密码',
    ty: '请输入正确的格式',
    agree: '登录即代表您同意用户隐私政策',
    com: '北京汉雅天成教育科技有限公司',
    ys: '用户隐私',
  },
  // 用户注册
  register: {
    zc: '用户注册',
    email: '邮箱账号',
    yzm: '验证码',
    xyb: '下一步',
    hq: '获取验证码',
    ty: '请输入正确的邮箱格式',
    errorEmail: '邮箱地址格式错误',
    inputCode: '请输入验证码',
    hasAcc: '已有账户, 直接',
    log: '登录',
    tea: '我是老师',
    stu: '我是学生',
    confirmPassWord: '确认密码',
    ppw: '请输入密码',
    zccg: '注册成功',
  },
  // 个人中心
  user: {
    personData: '个人中心',
    myOrder: '我的订单',
    logout: '安全退出',
  },
  // 忘记密码
  forget: {
    title: '忘记密码',
    email: '邮箱账号',
    code: '验证码',
    pl: '请输入验证码',
    ps: '请输入密码',
    cps: '请确认密码',
    giveUp: '放弃修改，直接',
    login: '登录',
  },
  // 首页模块
  homePage: {
    // 多维发展
    dw: '多维发展 助力新时代国际中文教育',
    dw_1: '以“长城汉语”精品课程为核心',
    dw_2: '以人工智能、网络多媒体等信息技术为支撑',
    dw_3: '以多维度、一体化品牌发展为策略',
    bz: '标准化发展',
    bzDec:
      '参照《欧洲语言共同参考框架（CEFR）》、美国《21世纪外语学习标准》5C，长城汉语对标《国际汉语教学通用课程大纲》、《国际中文教育中文水平等级标准》，做到“教有所依”。',
    bt: '本土化发展',
    btDec:
      '以专为阿拉伯语国家设计编写的《长城汉语》（阿语篇）为代表，做到“因地制宜”。',
    zy: '职业化发展',
    zyDec:
      '以面向餐饮专业汉语学习者编写的《长城汉语》（餐饮篇）为代表，响应“一带一路”和“汉语+”的倡议，探索汉语教学与职业发展有机融合的策略，以促进语言能力与专业技能同步提升。',
    sc: '素材化发展',
    scDec:
      '以多种智能工具支持素材化资源建设，依托于卡片智能生成系统、分级读物编写系统、智能出题系统，以及彰显中华传统文化主题的《长城汉语文化园》视频等，满足本土化教学的精细化需求。',
    zn: '智能化发展',
    znDec:
      '推出一站式解决教学与教务管理的长城汉语智慧云平台，上线融分级阅读、识字、记词、语典、课程于一体的汉雅国际-学生端APP，满足学生任何时间、任何地点、任何方式的个性化学习需求。',
    // 模式创新
    ms: '模式创新 丰富多元的教学模式',
    ms_1: '长城汉语采用半学术化发展思路，逐步从设计认同走向市场认同',
    ms_2:
      '学术发展层面，以“长城汉语”为研究专题的SSCI、硕博士、核心期刊等论文已经超过70余篇',
    ms_cc: {
      t: '“面授上机自主学习模式”',
      m: '适用于学生有规律的自主学习',
      b: '长城汉语中心',
    },
    ms_zy: {
      t: '“大综合教学模式”',
      m: '适用于学期制综合性教学',
      b: '中央民族大学',
    },
    ms_bjw: {
      t: '“大小课教学模式”',
      m: '适用于学期制视听说教学',
      b: '北京外国语大学',
    },
    ms_zym: {
      t: '“讲练复练短期强化教学模式”',
      m: '适用于短期汉语教学',
      b: '中央民族大学',
    },
    ms_mh: {
      t: '“视听说教学模式”',
      m: '适用于学期制视听说教学',
      b: '民航干部管理学院',
    },
    // 丰富的教学资源
    ff: '丰富的教学资源',
    ff_1:
      '以《长城汉语》系列课程为核心，以人工智能技术与网络多媒体技术为支撑，多维度、一体化发展',
    ff_left: {
      title: '《长城汉语》 生存交际（1～6）册',
      content:
        '《长城汉语》生存交际6个级别产品，共包含60个单元、180篇课文；其话题涉及个人信息、生存交际、日常生活、学习与交友、参与社会及朋友交往等6个方面，涵盖973个汉字、1300多个词汇、156个语法点、240个场景、262个交际要点等内容。',
    },
    ff_right: {
      title: '《长城汉语》 拓展交际（7～12）册',
      content:
        '《长城汉语》拓展交际6个级别产品，共包含60个单元、120篇课文；其话题涉及群组信息、拓展交际、日常生活、学习与工作、社会交往及综合交际等6个方面，涵盖227交际话题、1283个词汇、142个语法点、118个场景、251个交际要点等内容。',
    },
    ff_left2: {
      title: '《长城汉语》 餐饮版',
      content:
        '教材面向餐饮专业的汉语学习者，以HSK三级词汇大纲、语法大纲为依据，遵循汉语作为第二语言习得的特点以及汉语国际教育的理念，采取专项语言技能培养与综合语言能力提升相结合的教学模式，实现语言能力与专业技能同步提升的目标。',
    },
    ff_right2: {
      title: '《长城汉语》阿拉伯语版',
      content:
        '《长城汉语》阿拉伯语版，面向初、中、高级汉语学习者，共设计三个等级系列，分别为生存交际、拓展交际、自由交际。每个等级系列之间既相互衔接，又彼此各自独立。各个等级均由纸版课本、网络多媒体课件构成，既可用于课堂教学，也可用于自学。',
    },
    // 我们的优势
    wm: '我们的优势',
    wmTitle1: '“长城汉语”是孔子学院总部规划、组织、研发、运营的重点项目',
    wmTitle2:
      '自2003年立项以来，长城汉语与时俱进，持续致力于将智能技术与语言教学有机融合',
    wmContent: {
      wmContent1: {
        t: '名师指导',
        c: '外合作教师50+；',
      },
      wmContent2: {
        t: '多元教学模式',
        c:
          '教学实践层面，全球汉语教师使用《长城汉语》多媒体课程，成功地探索出了10+种丰富多元的教学模式；',
      },
      wmContent3: {
        t: '多终端覆盖',
        c:
          '自2005年至今，长城汉语已经从最初的局域网版（写实版风格），发展成为互联网版（Q版风格、写实版风格）、单机版、教师版、志愿者版，移动端（iPad和手机）、3D、VR以及纸质教材等诸多类型的产品；',
      },
      wmContent4: {
        t: '贴近生活故事',
        c:
          '《长城汉语》由著名编剧张作民老师创作故事大纲，北京语言大学专业教师团队执笔编写，将麦克的爱情故事、金太成的创业故事、菲雅的当代故事与玛丽的传奇故事四条主线融为一体，故事情节跌宕，人物性格鲜明；',
      },
      wmContent5: {
        t: '多语种适配',
        c:
          '为契合本土化市场需要，长城汉语多媒体课程含有英语、法语、德语、俄语、日语、韩语、泰语、意大利语、西班牙语、匈牙利语、荷兰语、波兰语、阿拉伯语、等13个语种，供用户自主选择；',
      },
      wmContent6: {
        t: '半学术化发展',
        c:
          '在学术发展层面，以“长城汉语”为研究专题的SSCI、硕博士、核心期刊等论文已经超过70余篇，在理论探索、实践验证、模式创新、资源建设等诸多方面，提供了多元文化背景、多种应用场景以及案例式的经验借鉴，为长城汉语的可持续发展注入了鲜活的思想动力；',
      },
      wmContent7: {
        t: '配套资源庞大',
        c:
          '其课程新增庞大的练习题库、《汉雅文化园》等配套辅助资源。题库内拥有16800多道同步练习题、120套学生用自测试卷、36套教师用级别测试卷，可以轻松布置作业，考练结合。《汉雅文化园》甄选120个中华传统文化主题，精美视频助力文化教学；',
      },
      wmContent8: {
        t: '智能教学工具',
        c:
          '卡片智能生成系统，海量字词卡片集，11种样式模板，按需定制在线直播系统，内置丰富交互工具，寓教于乐，支持多样化授课作业管理系统，同步题库，考练结合，个性化组题，一键推送；',
      },
    },
    // 客户解决方案
    kh: '客户解决方案',
    kh_b: {
      content1: '搭建一站式管理平台集成教学、教务系统',
      content2: '提供多种合作模式',
      content3: '支持个性化灵活定制',
      content4: '为高校和培训机构',
      content5: '提供全方位的优质服务',
    },
    // 免费获取解决方案
    free: '免费获取解决方案',
    getFree: {
      title: '获取解决方案',
      name: '您的姓名',
      tel: '您的联系电话或邮箱',
      com: '公司名称',
      sub: '提交',
    },
    // 我们的使命
    our: {
      t: '我们的使命',
      c: '助力学科建设 助建生态服务 助推模式创新 助帮品牌成长',
    },
    now_re: {
      l: '立即注册学习汉语',
      r: '立即注册',
    },
  },
  // 头部模块
  header: {
    zhy: '智慧云平台',
    person: {
      personCenter: '个人中心',
      myOrder: '我的订单',
      goOut: '安全退出',
    },
  },
  // footer 底部模块
  footer: {
    tel: '电话',
    phone: '手机',
    email: '邮箱',
    fax: '传真',
    address: '地址',
    // 快速链接
    fastLink: '快速导航',
    c: {
      home: '首页',
      class: '长城课堂',
      smart: '智慧云平台',
      study: '留学招生',
      res: '智能工具',
      news: '留学招生',
      about: '新闻中心',
      person: '关于我们',
    },
    r: {
      pro: '产品下载',
      one: '汉雅国际（学生端）',
      two: '汉雅- HSK学习词典',
      three: '汉雅-分级阅读',
      four: '汉雅词记',
    },
    comAdd: '北京市海淀区双清路3号鸿运大厦31025',
    upload: '产品下载',
    mess:
      '北京汉雅天诚教育科技有限公司版权所有 Copyright © Beijing Han-sky Education Technology Co., Ltd. All Rights Reserved.',
    mess1: '京公网安备11010802029663号',
    mess2: '京ICP证100724号 京ICP备15041788号-5',
  },
  // 关于我们
  aboutUs: {
    about: '北京汉雅天诚教育科技有限公司',
    aboutContent1:
      '北京汉雅天诚教育科技有限公司成立于2009年11月，总部位于北京市海淀区。是一家以诚信为本、以传承中华文化为己任的高新技术企业，亦是具有领先优势的教育科技与大数据技术服务提供商。公司已取得“国家高新技术企业”“中关村高新技术企业”资质，以自主创新＋优质服务作为企业核心竞争力，核心团队成员25%以上拥有国内外知名学府硕士或博士学位。',
    aboutContent2:
      '公司在自然语言处理技术特别是中文信息处理技术与核心资源开发等方面有着深厚的积淀，已拥有多项自主知识产权。公司致力于通过领先的技术、丰富的资源、成熟的产品、高品质的服务，为各类机构、广大教师和学习者提供汉语语言学习产品、网络教育平台、课程资源、智能软件系统开发以及教育咨询服务，力求把语言教学从标准建设、平台建设、资源建设、服务体系建设，以及师资培训几方面融合贯通，真正构建起融教、学、练、测、评一于体化的语言学习数字化生态。',
    aboutContent3:
      '公司主要业务领域除国际中文教育外，还在国民语文教育、海外华文教育、少数民族双语教育、游学与文化交流等方面都有深耕，创新性地为政府、院校和企业提供优质的解决方案。',
    aboutContent4:
      '公司是原孔子学院总部“长城汉语中心”的实体运营机构，亦是中国教育部中外语言交流合作中心正式授权机构，自公司成立以来便一直为该品牌的持续建设和推广而贡献力量，公司核心团队成员已经在国际中文教育领域深耕超过18载，积淀了大量的科研与教学实践成果，并促使这一品牌顺应时代发展，成为了经得住考验的国际中文教育品牌资源。',
    develop: '发展历程',
    developContent: '',
    com: '公司产品',
    comContent: '',
    // 产品模块
    product: {
      one: {
        title: '汉雅国际（学生端）',
        con1: '个性化、多样化，构建汉语学习新模式',
        con2: '功能简介：课程中心/班级/学习轨迹/分级阅读/识字/记词/动',
      },
      two: {
        title: '汉雅-HSK学习语典',
        con1:
          '以初级阶段的汉语学习者为主要服务群体，突出学习功能，主要解决他们在学习过程中所遇到的字、词、“短语”甚至语法方面的问题。',
        con2: '功能简介：精选语条/常用句式/图解词语/挑战认知等',
      },
      three: {
        title: '汉雅-分级阅读',
        con1:
          '一款为提高汉语学习者阅读能力而专门设计的产品，分为学生版、教师版。',
      },
      four: {
        title: '汉雅-记词',
        con1:
          '专为汉语学习者设计的一款词汇“识记”APP。主要提供《新HSK词汇等级大纲》和《长城汉语》词汇，也可针对用户的个性化需求定制词汇本。目前支持汉语、英语、西班牙语三种语言版本',
        con2: '',
      },
    },
    download: '立即下载',
  },
  // 在线工具
  tools: {
    chinese: '中文',
    pinyin: '拼音',
    // zi: {
    //   grid: {
    //     title: '底格样式设置',

    //   }
    // }
  },
  // 个人中心
  pd: {
    userRole: 'Student',
    smartCloud: '智慧云平台',
    grzl: {
      // 个人中心
      tab: {
        person: '个人信息',
        password: '修改密码',
      },
      con: {
        userPhoto: '头像',
        email: '邮箱',
        phone: '手机',
        nickName: '昵称',
        name: '姓名',
        country: '国家',
        city: '城市',
        des: '简介',
        placeHolder: '请输入',
        old: '旧密码',
        new: '新密码',
        confirm: '新密码确认',
      },
      save: '保存',
      cancel: '取消',
      change: '更换头像',
      c_upload: '点击上传',
      preview: '头像预览',
      only: '(支持200kb以下JPG,PNG格式的图片)',
    },
    zbrl: {
      title: '直播日历',
    },
    wddd: {
      // 我的订单
      all: '全部',
      paid: '已付款',
      pending: '待付款',
      canceled: '已取消',
      noOrder: '还没有任何订单哦～',
    },
  },
  // 留学招生
  recruit: {
    title: '2021',
    small_title: '中国留学申请指南',
    name: '您的姓名',
    email: '您的邮箱',
    country: '您的国籍',
    school: '您的意向学校',
    apply: '立即申请',
    look: '查看招生简章',
    downLoad: '下载',
    more: '敬请期待更多院校',
  },
  // 产品介绍
  product: {
    zd: {
      l: '终端、平台、应用、数据，四位一体',
      r: '教、学、管、测、评，全面覆盖',
    },
    // 长城汉语教学
    jx: {
      title: '长城汉语教学',
      contentTitle: '品牌教学资源',
      con1: '内嵌语言训练模式，科学有序',
      con2: '练习形式多样，同步专项训练',
      con3: '动画生动形象，语言真实鲜活',
      con4: '语音评测，即时反馈',
      con5: '手写识别，交互性强',
    },
    // 智慧教学管理
    gl: {
      title: '智慧教学管理',
      s_title: '课前、课中、课后闭环式教学方法，创建一站式智慧教学环境',
      l: {
        title_top: '辅助备课',
        title_bot: '课前',
        tm: '卡片智能生成系统',
        num1: '5000',
        tm1: '余张字、词卡片，可自由选择下载',
        num2: '11',
        tm2: '种卡片样式模板，可自主在线编辑，批量生成，省时高效',
        bot_title: '分级阅读编辑系统',
        bot_con1: '辅助教师补充课外读物，进行拓展教学；',
        bot_con2: '可按HSK等级大纲将文章分级，轻松编辑、发布，一键推送',
      },
      c: {
        title_top: '课堂教学',
        title_bot: '课中',
        con1: '汉雅在线直播系统，实现线上、线下混合式教学服务模式',
        con2: '内置丰富交互工具，寓教于乐',
        con3: '支持多角色、多班型、多样化授课',
      },
      r: {
        title_top: '课后管理',
        title_bot: '课后',
        con1: '在线作业 实时推送',
        con2: '消息通知 快捷直达',
        con3: '智能评阅 科学准确',
        con4: '学习情况 智能监测',
        con5: '学习进度 一目了然',
        con6: '学情分析 反拨教学',
      },
    },
    // 长城汉语云教务
    jw: {
      title1: '长城汉语云教务',
      content1: '紧贴需求，全面覆盖 解决痛点，一体化管理 及时高效，全程共享',
      title2: '多层级、多角色，权限分明',
      content21: '多校区、跨部门、多层级、多角色，云端协同合作',
      content22: '按需配置权限，有序查看、管理数据，提高工作效率。',
      title3: '招生信息全记录，学员管理精细化',
      content31: '意向学员随时跟进，信息提醒智能及时;',
      content32: '招生统计全面记录，开设班级统筹安排;',
      content33: '销售策划去繁就简，合作代理形式多样。',
      title4: '智能排课，直观清晰，快捷高效',
      content41: '排课功能突破约束条件;',
      content42: '满足班级、课型、学时等要求;',
      content43: '灵活指定时间、地点、教学区。',
      title5: '统计信息清晰明了，决策制定科学有序',
      content51: '可按校区、时段统计招生数据可实现资源优化配置;',
      content52: '可分学员统计课时及课时消耗;',
      content53: '可分教师统计排课量及课时消耗;',
      content54: '提高资金利用效率。',
    },
  },
  // 长城课堂详情
  cd: {
    next: '下次直播',
    topic: '课题',
    date: '时间',
    lecture: '讲师',
    purchased: '报名',
    buy: '立即购买',
    buy1: '购买',
    buyer: '人已',
    purchased1: '报名',
    ks: '课时',
    kc: '课程介绍',
    jj: '目录',
    ml: '教材',
    xg: '相关推荐',
    zw: '暂无课程安排',
    detail: '【《长城汉语》配套纸质教材已出版，点击下面 [教材] 购买】',
    yxq: '有效期至',
    contact: '联系人',
    contactName: '张老师',
    contactInfo: '联系电话',
    cellPhone: '(86) - 13146512290',
  },
  // 我的购买订单模块
  myPay: {
    dd: '订单信息确认',
    kc: '课程信息',
    dm: '多媒体课程',
    gzc: '共支持',
    gac_b: '课时',
    sy: '使用权限，购买后一年内有效',
    qxz: '请选择支付方式',
    zy: '注意：请在30分钟内完成支付，否则订单会被自动取消',
    yf: '应付总额',
    lj: '立即支付',
    ddxx: '订单信息确认',
    ddbh: '订单编号',
    gmkc: '购买课程',
    xdrq: '下单日期',
    zfje: '支付金额',
    jxgm: '继续购买',
    ygm: '已购买',
  },
  // 提示语
  tips: {
    title: '提示',
    loginSuccess: '登录成功',
  },
};

export default cn;
