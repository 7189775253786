const en = {
  // 用户登录
  login: {
    login: 'User Login',
    userEmail: 'Please input username/email/phone number',
    ru: 'Remember user name',
    fr: 'Forget password',
    Login: 'Login',
    qt: 'Other login methods',
    zc: 'Register',
    hasAcc: 'Existing account,',
    passWord: 'Password',
    ty: 'Typing',
    agree: 'By logging in, you agree to the user privacy policy.',
    com: 'Beijing Han-sky Education Technology Co., Ltd. ',
    ys: 'User privacy',
  },
  // 用户注册
  register: {
    zc: 'User Registration',
    email: 'E-mail',
    yzm: 'Verification Code',
    xyb: 'Submit',
    hq: 'Get Code',
    ty: 'Typing',
    errorEmail: 'Email address format is wrong',
    inputCode: 'Please input the verification code ',
    hasAcc: 'Existing account, directly',
    log: 'login',
    tea: 'I am a teacher',
    stu: 'I am a student',
    confirmPassWord: 'Password confirmation',
    ppw: 'Password',
    zccg: 'register success',
  },
  // 个人中心
  user: {
    personData: 'Personal Data',
    myOrder: 'My Order',
    logout: 'Log-out',
  },
  // 忘记密码
  forget: {
    title: 'Forget password',
    code: 'Code',
    pl: 'Please input the verification code',
    ps: 'Password',
    cps: 'Password confirmation',
    giveUp: 'Discard the modification and ',
    login: 'login',
  },
  // 首页
  homePage: {
    dw:
      'Multidimensional development Facilitate international Chinese education in the new era',
    dw_1: 'Take the high-quality course of "Great Wall Chinese" as the core',
    dw_2:
      'Supported by artificial intelligence, network multimedia and other information technology',
    dw_3:
      'Take multi-dimensional and integrated brand development as the strategy',
    bz: 'Standardized Development ',
    bzDec:
      'Refer to The Common European Framework of Reference for Languages, Standards for Foreign Language Learning in the 21st Century 5C and Great Wall Chinese benchmarked International Curriculum for Chinese  Language Education and Chinese Proficiency Grading Standards for International Chinese Language Education, so as to achieve "basis for teaching".',
    bt: 'Localized Development ',
    btDec:
      'Represented by the Great Wall Chinese (Arabic version), which is specially designed and prepared for Arabic countries to "adjust measures to local conditions". ',
    zy: 'Professional Development ',
    zyDec:
      'The Great Wall Chinese (catering version) for Chinese learners in the catering industry is a response to the " The Belt and Road Initiative" and "Chinese + initiative" to explore strategies for the organic integration of Chinese language teaching and professional development, so as to promote simultaneous improvement of language ability and professional skills. ',
    sc: 'Materialized Development',
    scDec:
      'Support the construction of materialized resources with a variety of intelligent tools, relying on the card intelligent generation system, the graded reader writing system, the intelligent question setting system, and the "Great Wall Chinese Culture Park" video that highlights the theme of Chinese traditional culture, to meet the refined needs of localized teaching. ',
    zn: 'Intelligent Development ',
    znDec:
      'Launch the Great Wall Chinese Smart Cloud Platform, which is a one-stop solution for teaching and education management, and launch a Han-sky International-Student App, which integrates graded reading, learning to read, word memorization, language dictionary and curriculum, to meet the personalized learning needs of students at any time, any place and in any way. ',
    // 模式创新
    ms: 'Model innovation Rich and diversified teaching mode ',
    ms_1:
      'Great Wall Chinese adopts a semi-academic development idea and gradually moves from design identity to market identity ',
    ms_2:
      'At the academic development level, there have been more than 70 papers in SSCI, masters, doctors, and core journals with "Great Wall Chinese" as the research topic',
    ms_cc: {
      t:
        '"The Mode of Multi-media Courseware Self-study+ The Mode of Face-to-face Tutoring"',
      m: "It is suitable for students' regular self-learning.",
      b: 'Great Wall Chinese Center',
    },
    ms_zy: {
      t: '"Overall comprehensive teaching mode"',
      m: 'It is suitable for comprehensive teaching in the semester system.',
      b: 'Minzu University of China',
    },
    ms_bjw: {
      t: '"Various class capacity teaching mode"',
      m: 'It is suitable for comprehensive teaching in sthe emester system.',
      b: 'Beijing Foreign Studies University',
    },
    ms_zym: {
      t:
        '"Short term intensive teaching mode of speech practice - repetition practice"',
      m: 'It is suitable for short-term teaching course of 2-10 weeks.',
      b: 'Minzu University of China',
    },
    ms_mh: {
      t: '"Audio-visual-oral teaching mode"',
      m:
        'It is suitable for the audio-visual-oral teaching mode in the semester system.',
      b: 'Civil Aviation Management Institute of China',
    },
    // 丰富的教学资源
    ff: 'Rich teaching resources',
    ff_1:
      'taking the dedicated course of "Great Wall Chinese" as the core, the information technology such as artificial intelligence, network multimedia as the support, multi-dimension, integrated brand development as the strategy',
    ff_left: {
      title: 'Essentials in Communication(1~6)',
      content:
        'The product of “Great Wall Chinese-Survival Communication” contains 6 levels, include 60 unites and 180 texts. The topics relate to personal information, survival communication, daily life communication, work and study, participating in society and friend communication, etc, covering more than 973 characters, over 1,300 vocabularies, 156 grammar points, 240 scenarios, 262 communication points and so on.',
    },
    ff_right: {
      title: 'Progression in Communication(7～12)',
      content:
        'The product of “Great Wall Chinese- Expansion in Communication” contains 6 levels, include 60 unites and 120 texts. The topics relate to group information, expanding communication, daily life, learning and work, social communication and comprehensive communication, covering 227 communication topics, 1,283 words, 142 grammar points, 118 scenes, 251 communication points, and so on',
    },
    ff_left2: {
      title: 'Arabic Version (Elementary)',
      content:
        'The Arabic chapter of Great Wall Chinese is designed for junior, middle and senior Chinese learners and has a series of three grades, namely survival communication, expansion communication and free communication. Each grade in this series is not only connected with each other, but also independent of each other. Each grade consists of a paper-based textbook and online multimedia courseware, which can be used for both classroom teaching and self-study. ',
    },
    ff_right2: {
      title: 'Catering Version (Volumes I and II) ',
      content:
        'he catering chapter of Great Wall Chinese is for Chinese learners who are in the catering industry. Based on HSK level III vocabulary outline and grammar outline, following the characteristics of Chinese as a second language acquisition and the concept of Chinese international education, it adopts the teaching mode of combining special language skill training with comprehensive language ability improvement, so as to achieve the target of simultaneous improvement of language ability and professional skills. ',
    },
    // 我们的优势
    wm: 'Our advantages',
    wmTitle1:
      '"Great Wall Chinese" is a key project in the planning, organization, R & D and operation of the former Confucius Institute Headquarters',
    wmTitle2:
      ' Since the establishment of the project in 2003, Great Wall Chinese has been advancing with the times and has continued to devote itself to the organic integration of intelligent technology and language teaching.',
    wmContent: {
      wmContent1: {
        t: 'Famous teachers guidance ',
        c: 'Foreign cooperative teachers 50+',
      },
      wmContent2: {
        t: 'Multiple teaching mode ',
        c:
          'At the level of teaching practice, Chinese language teachers around the world have successfully explored ten more rich and diversified teaching models by using the multimedia course of Great Wall Chinese ',
      },
      wmContent3: {
        t: 'Multi-terminal coverage ',
        c:
          'Since 2005, Great Wall Chinese has developed from the initial LAN version (Realistic style) to the Internet version (Q style, Realistic style), stand-alone version, teacher version, volunteer version, mobile terminal (iPad and mobile phone), 3D, VR and many other types of products. ',
      },
      wmContent4: {
        t: 'Close to life stories ',
        c:
          'The story outline of Great Wall Chinese was created by the famous screenwriter Zhang Zuomin and written by the professional teacher team of Beijing Language and Culture University. It combines the love story of Mike, the entrepreneurial story of Jin Taicheng, the contemporary story of Feiya and the legendary story of Mary into a single story, with a strong twist and turn storyline and distinct characters; ',
      },
      wmContent5: {
        t: 'Multilingual adaptation',
        c:
          'It supports 13 languages, including English, French, Russian, Dutch, Japanese, Korean, Thai, German, Italian, Spanish, Hungarian, Arabic, Polish',
      },
      wmContent6: {
        t: 'Academic Development ',
        c:
          "At the level of academic development, more than 100 papers such as SSCI, master's degree and doctor's degree, core journals, etc. on the topic of 'Great Wall Chinese', which have provided multi-cultural backgrounds, various application scenarios and case-based experiences in many aspects such as theoretical exploration, practical verification, model innovation and resource construction, which has injected fresh ideological power into the sustainable development of Great Wall Chinese.",
      },
      wmContent7: {
        t: 'Huge Supporting Resources ',
        c:
          'Its courses add a huge bank of practice questions, Han-sky Culture Park and other supporting auxiliary resources. The question bank contains more than 17,000 synchronous practice questions, 60 sets of self-test papers for students, and 18 sets of test papers for teachers level, which can easily arrange homework and combine examination with practice. Han-sky Culture Park selects 120 traditional Chinese cultural themes, with beautiful videos to help teach culture.',
      },
      wmContent8: {
        t: 'Intelligent Teaching Tools ',
        c:
          "Card intelligent generation system, massive word card sets, 11 style templates, customized on demand; Online live broadcasting system, built-in rich interactive tools, teaching in fun and supporting diversified teaching; Online homework management system, synchronous question bank, combination of examination and practice, personalized question group, One-click push. There are also tools such as automatic Pinyin generation and Chinese character stroke splitting to help teachers' teaching.",
      },
    },
    // 客户解决方案
    kh: 'Customer Solutions',
    kh_b: {
      content1:
        'Build a one-stop management platform to integrate educational administration system, teaching system and learning system in one ',
      content2: 'Provide a variety of cooperation modes',
      content3: 'Support personalized and flexible customization',
      content4:
        'For colleges and training institutions at home and abroad Provide a full range of high-quality services',
      content5: 'Provide a full range of high-quality services',
    },
    // 免费获取解决方案
    free: 'Get the solution for free ',
    getFree: {
      title: 'Access to Solution',
      name: 'Your name',
      tel: 'Your contact number or email',
      com: "Company's name",
      sub: 'Submit',
    },
    // 我们的使命
    our: {
      t: 'Our Mission',
      c:
        'Help discipline construction  Help ecological services construction  Boost model innovation  Help brand growth ',
    },
    now_re: {
      l: 'Register now and start learning Chinese ',
      r: 'Register Now ',
    },
  },
  header: {
    zhy: 'Smart Cloud Platform ',
    person: {
      personCenter: 'Personal Data ',
      myOrder: 'My Order ',
      goOut: '安全退出',
    },
  },
  // footer 底部模块
  footer: {
    tel: 'Tel',
    phone: 'Cell Phone',
    email: 'Email',
    fax: 'Fax',
    address: 'Address',
    // 快速链接
    fastLink: 'Quick Links',
    c: {
      home: 'Home',
      class: 'Classroom',
      smart: 'Smart Cloud Platform',
      study: 'Study abroad enrollment',
      res: 'Resource center',
      news: 'News Center ',
      about: 'About Us ',
      person: 'Personal Center ',
    },
    r: {
      pro: 'Product download',
      one: 'Han-sky International app ( for students) ',
      two: 'Han-sky-HSK learning dictionary',
      three: 'Han-sky-Graded reading',
      four: 'Han-sky - Words Memorizing',
    },
    comAdd:
      'Room 31025, Hongyun Building, No.3 of Shuangqing Road, Haidian District, Beijing, 100085',
    upload: 'Products Download',
    mess: 'Beijing Han-sky Education Technology Co., Ltd. All Rights Reserved.',
    mess1: 'JGWAB No.11010802029663',
    mess2: 'J. ICP Certificate No.100724 J. ICP B No.15041788-5',
  },
  // 关于我们
  aboutUs: {
    about: 'Beijing Han-sky Education Technology Co., Ltd.',
    aboutContent1:
      'Beijing Han-sky Education Technology Co., Ltd., was established in November 2009, and its headquarter is in Haidian District, Beijing, which is a high-tech enterprise based on good faith and inheriting and developing Chinese culture as its duty, and is also a leading provider of education technology and big data technology. The company has obtained the qualification of "National High-Tech Enterprise" and "Zhongguancun High-Tech Enterprise", with independent innovation + high-quality service as the core competitiveness of the company, and more than 25% of the core team members have master degrees or doctoral degrees from famous universities at home and abroad.',
    aboutContent2:
      'The company has a deeply profound accumulation in natural language processing technology, especially in Chinese information processing technology and core resources development, with a number of independent intellectual property rights. The company is committed to providing Chinese language learning products, online education platforms, curriculum resources, intelligent software system development and educational consulting services for various institutions, teachers and learners through leading technology, rich resources, mature products and high quality services, and strives to achieve and integrate mastery on language teaching from the standard construction, platform construction, resource construction and service system construction to truly realize the industry model of integration of teaching, learning, management, testing, and evaluation.',
    aboutContent3:
      "In addition to international Chinese language education, the company's main business fields, including national language education, overseas Chinese language education, bilingual education for ethnic minorities, study tours, cultural exchanges and others, continue to provide quality solutions for governments, institutions and enterprises.",
    aboutContent4:
      "The company is the entity operating organization of the Great Wall Chinese Language Center, the former Confucius Institute Headquarters, and is also an officially authorized organization of the Chinese Ministry of Education's Center for Language Education and Cooperation. Since its establishment, the company has been contributing to the continuous construction and promotion of the brand. The members of the company's core team have been deeply dug down the field of international Chinese education for more than 18 years, which has accumulated a large number of achievements in scientific research and teaching practice, and promoted this brand to adapt to the development of the times and become a tested International Chinese education brand resource.",
    develop: 'Development history',
    developContent: '',
    com: 'Company Products',
    comContent: '',
    // 产品模块
    product: {
      one: {
        title: 'Han-sky International app ( for students)',
        con1:
          'Individualization and diversification to build a new model of Chinese learning ',
        con2:
          'Introduction to the function: Course center / classes / learning tracks / graded reading / literacy / word recognition / motivation ',
      },
      two: {
        title: 'Han-sky-HSK learning dictionary',
        con1:
          'It caters to Chinese learners of the primary stage and highlights the learning function, which mainly solves the problems of Chinese characters, words, "phrases" and even grammar in the process of learning. ',
        con2:
          'Introduction to the function: Selected phrases/common sentence patterns /illustrated words by graphics/challenging perceptions, etc. ',
      },
      three: {
        title: 'Han-sky-Graded reading',
        con1:
          "A product designed specially for improving Chinese learners' reading ability is divided into student version and teacher version ",
      },
      four: {
        title: 'Han-sky - Words Memorizing',
        con1:
          "A vocabulary 'memorization' APP designed for Chinese learners specially. It mainly provides vocabulary collected in New HSK Vocabulary Syllabus and Great Wall Chinese, which can also customize the vocabulary book according to the user's personalized needs. At present, it supports Chinese, English and Spanish versions ",
        con2: '',
      },
    },
    download: 'Download',
  },
  // 在线工具
  tools: {
    chinese: 'Chinese',
    pinyin: 'Pinyin',
  },
  // 个人中心
  pd: {
    userRole: 'Student',
    smartCloud: 'Smart Cloud Platform',
    grzl: {
      tab: {
        person: 'Personal information',
        password: 'Modify password',
      },
      con: {
        userPhoto: 'Profile Photo',
        email: 'Email',
        phone: ' Phone Number',
        nickName: 'Nickname',
        name: 'Name',
        country: 'Country',
        city: 'City',
        des: 'Introduction',
        old: 'Old password',
        new: 'New password',
        confirm: 'New password confirmation',
      },
      save: 'Save',
      cancel: 'Cancel',
      change: 'upload',
      c_upload: 'Click to upload',
      preview: 'preview your profile photo',
      only: '(Only JPG or PNG format pictures below 200KB is available)',
    },
    zbrl: {
      title: 'Live Broadcasting Calendar',
    },
    wddd: {
      // 我的订单
      all: 'All',
      paid: 'Paid',
      pending: 'Pending payment',
      canceled: 'Canceled',
      noOrder: 'no order~',
    },
  },
  // 留学招生
  recruit: {
    title: '2021',
    small_title: 'Application guide for studying in China',
    name: 'Your name',
    email: 'Your email',
    country: 'Your school',
    school: 'Your intended school',
    apply: 'Apply now',
    look: 'Enrollment Regulation',
    downLoad: 'Download',
    more: 'Stay tuned for more colleges and universities ',
  },
  // 产品介绍
  product: {
    zd: {
      l:
        'Great Wall Chinese Center has implemented the "terminal, platform, application, data and Serve" five-in-one',
      r:
        'Teaching, learning, management, testing and evaluation are fully covered',
    },
    jx: {
      title: 'Great Wall Chinese Teaching ',
      contentTitle: 'Brand Teaching Resources ',
      con1: 'Embedded language training mode, scientific and orderly ',
      con2: 'Various forms of practice, and synchronous special training ',
      con3: 'Vivid animation, and real and live language ',
      con4: 'Voice assessment and instant feedback ',
      con5: 'Handwritten recognition and strong interaction',
    },
    gl: {
      title: 'Smart teaching management ',
      s_title:
        'Closed-loop teaching method before, during and after class to create a one-stop smart teaching environment ',
      l: {
        title_top: 'Auxiliary lesson preparation ',
        title_bot: 'Pre-class',
        tm: 'Card intelligent generation system ',
        num1: '5000',
        tm1:
          'More than 5000 word and phrase cards, which can be downloaded freely.11 card style templates can be independently edited online and generated in batch, which can be timesaving and efficient. ',
        num2: '11',
        tm2:
          'Assist teachers in supplementing extra-curricular readings for extended instruction. According to HSK syllabus, the article can be graded, edited easily, published and pushed by one-click ',
        bot_title: 'Graded reading and editing system ',
        bot_con1:
          'Assist teachers in supplementing extra-curricular readings for extended instruction. ',
        bot_con2:
          'According to HSK syllabus, the article can be graded, edited easily, published and pushed by one-click',
      },
      c: {
        title_top: 'Classroom teaching ',
        title_bot: 'In-class',
        con1:
          'Han-sky online live broadcasting system realizes the online and offline mixed teaching service mode ',
        con2: 'Built-in rich interactive tools, teaching in fun',
        con3: 'Support multi-role, multi-class and diversified teaching ',
      },
      r: {
        title_top: 'Management after class',
        title_bot: 'Post-class',
        con1: 'Online homework  Real-time push',
        con2: 'Message notification  Convenient and instant ',
        con3: 'Intelligent review  Scientific and accurate ',
        con4: 'Learning situation  Monitor intelligently',
        con5: 'Learning progress  Clear at a glance',
        con6: 'Learning situation analysis  Backwash teaching',
      },
    },
    // 长城汉语云教务
    jw: {
      title1: 'Great Wall Chinese cloud educational administration',
      content1:
        'Close to the needs, comprehensive coverage to solve the pain points, integrated management of timely and efficient, the whole sharing ',
      title2: 'Multi-level and multi-role for clear authority',
      content21:
        'Multi-campus, cross-department, multi-level, multi-role for cloud collaborative cooperation Configure permissions on demand,',
      content22:
        ' view and manage data in an orderly manner, and improve work efficiency. ',
      title3:
        'Comprehensive record of enrollment information and fine management of students ',
      content31:
        'Intended students follow up at any time, and the information reminder is intelligent and timely',
      content32:
        'Comprehensive records of enrollment statistics and overall arrangement of classes ',
      content33:
        'Simplify sales planning, a variety of cooperative agency forms ',
      title4:
        'Intelligent course scheduling, intuitive and clear, fast and efficient ',
      content41: 'Course scheduling function breaks through constraints ',
      content42:
        'Meet the requirements of class, class type and class hours,etc.',
      content43: 'Designate time, place and teaching area flexibly',
      title5:
        'The statistical information is clear and the decision-making is scientific and orderly ',
      content51:
        'The enrollment data can be counted according to the campus and time period to realize the optimal allocation of resources',
      content52:
        'Statistics of class hours and class consumption is made according to students ',
      content53:
        'Statistics of course scheduling and class consumption is made according to teachers ',
      content54: 'Improve capital utilization efficiency ',
    },
  },
  // 长城课堂详情
  cd: {
    next: 'Next live class',
    topic: 'topic',
    date: 'Date',
    lecture: 'Lecture',
    purchased: 'Purchased',
    buy: 'Buy now',
    buy1: 'buyer',
    buyer: '',
    purchased1: 'Purchased',
    ks: 'class hours',
    kc: 'Course Introduction',
    ml: 'Course',
    jj: 'Contents',
    xg: ' Recommendations', // Relevant
    zw: 'No Contents Schedule',
    detail:
      '[The paper teaching materials Great Wall Chinese has been published, click [textbook] below to buy]',
    yxq: 'Valid until',
    contact: 'Contact',
    contactName: 'Mr. Zhang',
    contactInfo: 'Cell Phone',
    cellPhone: '(86) - 13146512290',
  },
  // 我的购买订单模块
  myPay: {
    dd: 'Order confirmation',
    kc: 'Course information',
    dm: 'Multimedia Course',
    gzc: 'A total of are supported',
    gac_b: 'class hours ',
    sy: 'Permission to use is valid  for one year from the date of purchase',
    qxz: 'Please select payment method',
    zy:
      'Notice: Please complete the payment within 30 minutes, otherwise the order will be automatically cancelled',
    yf: 'Total amount payable',
    lj: 'Pay instantly',
    ddxx: 'Order information confirm',
    ddbh: 'Order number',
    gmkc: 'Courses purchased',
    xdrq: 'Order date',
    zfje: 'Payment amount',
    jxgm: 'Continue to buy',
    ygm: 'Have bought',
  },
  // 提示语
  tips: {
    title: 'tips',
    loginSuccess: 'login success',
  },
};

export default en;
