import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/homeComponents/home.vue'),
    meta: { data: [{ title: '首页' }], requireAuth: true },
    children: [
      {
        // 首页信息
        path: '/home',
        name: 'homePage',
        component: () => import('../views/homeComponents/index.vue'),
        meta: { data: [{ title: '首页' }], requireAuth: true },
      },
      {
        // 长城课堂
        path: '/product',
        name: 'product',
        component: () => import('../views/product/index.vue'),
        meta: {
          data: [{ title: '长城课堂', titleEn: 'Great Wall Chinese' }],
          requireAuth: true,
        },
      },
      {
        // 长城课堂 基立学院购买用
        path: '/productJili',
        name: 'productJili',
        component: () => import('../views/product/indexJili.vue'),
        meta: {
          data: [{ title: '长城课堂', titleEn: 'Great Wall Chinese' }],
          requireAuth: true,
        },
      },
      {
        // 中文课堂
        path: '/chineseClassRoom',
        name: 'chineseClassRoom',
        component: () => import('../views/product/chineseClassRoom.vue'),
        meta: {
          data: [
            {
              title: '长城课堂',
              titleEn: 'Great Wall Chinese',
              requireAuth: true,
            },
            { title: '长城汉语网络中文课堂' },
          ],
          requireAuth: true,
        },
      },
      {
        // 书籍介绍
        path: '/bookIntroduce',
        name: 'BookIntroduce',
        component: () => import('../views/product/BookIntroduce.vue'),
        meta: {
          data: [
            {
              title: '长城课堂',
              titleEn: 'Great Wall Chinese',
              url: '/product',
              requireAuth: true,
            },
            { title: '书籍介绍', url: '/bookIntroduce' },
          ],
          requireAuth: true,
        },
      },
      {
        // 中文课堂详情页面
        path: '/classMessDetail',
        name: 'classMessDetail',
        component: () => import('../views/product/classMessDetail.vue'),
        meta: {
          data: [
            {
              title: '长城课堂',
              titleEn: 'Great Wall Chinese',
              url: '/product',
              requireAuth: true,
            },
          ],
          requireAuth: true,
        },
      },
      {
        // 中文课堂详情页面 基立学院购买用
        path: '/classMessDetailJili',
        name: 'classMessDetailJili',
        component: () => import('../views/product/classMessDetailJili.vue'),
        meta: {
          data: [
            {
              title: '长城课堂',
              titleEn: 'Great Wall Chinese',
              url: '/productJili',
              requireAuth: true,
            },
          ],
          requireAuth: true,
        },
      },
      {
        // 长城课堂  《长城汉语》
        path: '/survivalCommunication',
        name: 'survivalCommunication',
        component: () => import('../views/product/survivalCommunication.vue'),
        meta: {
          data: [
            {
              title: '长城课堂',
              titleEn: 'Great Wall Chinese',
              url: '/chineseClassRoom',
              requireAuth: true,
            },
            { title: '《长城汉语》', url: '/survivalCommunication' },
          ],
          requireAuth: true,
        },
      },
      {
        // 长城课堂  我的订单
        path: '/myOrder',
        name: 'myOrder',
        component: () => import('../views/product/myOrder.vue'),
        meta: {
          data: [{ title: '我的订单', requireAuth: true }],
          requireAuth: true,
        },
      },

      {
        // 智慧云平台
        path: '/wisdomPlatform',
        name: 'wisdomPlatform',
        component: () => import('../views/wisdomPlatform/index.vue'),
        meta: { data: [{ title: '智慧云平台' }], requireAuth: true },
      },
      {
        // 个人中心
        path: '/personCenter',
        name: 'personCenter',
        component: () => import('../views/personCenter/index.vue'),
        meta: { data: [{ title: '个人中心' }], requireAuth: true },
        children: [
          {
            path: '/xxbg',
            name: 'xxbg',
            component: () => import('../views/personCenter/xxbg.vue'),
            meta: { data: [{ title: '学习报告' }], requireAuth: true },
          },
          {
            path: '/zbrl',
            name: 'zbrl',
            component: () => import('../views/personCenter/zbrl.vue'),
            meta: { data: [{ title: '直播日历' }], requireAuth: true },
          },
          {
            path: '/wdsc',
            name: 'wdsc',
            component: () => import('../views/personCenter/zbrl.vue'),
            meta: { data: [{ title: '我的收藏' }], requireAuth: true },
          },
          {
            path: '/wddd',
            name: 'wddd',
            component: () => import('../views/personCenter/wddd.vue'),
            meta: { data: [{ title: '我的订单' }], requireAuth: true },
          },
          {
            path: '/dhkc',
            name: 'dhkc',
            component: () => import('../views/personCenter/dhkc.vue'),
            meta: { data: [{ title: '兑换课程' }], requireAuth: true },
          },
          {
            path: '/grzl',
            name: 'grzl',
            component: () => import('../views/personCenter/grzl.vue'),
            meta: { data: [{ title: '个人资料' }], requireAuth: true },
          },
        ],
      },
      {
        // 留学招生
        path: '/date',
        name: 'date',
        component: () => import('../views/personCenter/date.vue'),
      },
      {
        // 留学招生
        path: '/recruitStudent',
        name: 'recruitStudent',
        component: () => import('../views/recruitStudent/index.vue'),
      },
      {
        // PDF
        path: '/PDF',
        name: 'PDF',
        component: () => import('../views/newsCenter/PDF.vue'),
        meta: {
          data: [
            { title: '留学招生', url: '/recruitStudent', requireAuth: true },
          ],
          requireAuth: true,
        },
      },
      {
        // 新闻中心
        path: '/News',
        name: 'News',
        component: () => import('../views/newsCenter/News.vue'),
        meta: {
          data: [
            {
              title: '新闻中心',
              titleEn: 'News',
              url: '/recruitStudent',
              requireAuth: true,
            },
            { title: 'HSK考试', url: '/HSK' },
          ],
          requireAuth: true,
        },
      },
      {
        // HSK
        path: '/HSK',
        name: 'HSK',
        component: () => import('../views/newsCenter/HSK.vue'),
      },
      {
        // 新闻中心详情
        path: '/HSKDetail',
        name: 'HSKDetail',
        component: () => import('../views/newsCenter/HSKDetail.vue'),
        meta: {
          data: [
            {
              title: '新闻中心',
              titleEn: 'News',
              url: '/HSK',
              requireAuth: true,
            },
            { title: 'HSK考试', url: '/HSK' },
          ],
          requireAuth: true,
        },
      },
      {
        // 资源中心
        path: '/resourceCenter',
        name: 'resourceCenter',
        component: () => import('../views/resourceCenter/culturalPark.vue'),
        meta: {
          data: [
            { title: '资源中心', url: '/resourceCenter', requireAuth: true },
            { title: '文化园', url: '/culturalPark' },
          ],
          requireAuth: true,
        },
      },
      {
        // 资源中心 文化园
        path: '/culturalPark',
        name: 'culturalPark',
        component: () => import('../views/resourceCenter/culturalPark.vue'),
        meta: {
          data: [
            { title: '资源中心', url: '/resourceCenter', requireAuth: true },
            { title: '文化园', url: '/culturalPark' },
          ],
          requireAuth: true,
        },
      },
      {
        // 资源中心 文化传统
        path: '/culturalTradition',
        name: 'culturalTradition',
        component: () =>
          import('../views/resourceCenter/culturalTradition.vue'),
        meta: {
          data: [
            { title: '资源中心', url: '/resourceCenter', requireAuth: true },
            { title: '文化园', url: '/culturalPark' },
          ],
          requireAuth: true,
        },
      },
      {
        // 资源中心 智能工具
        path: '/smartTools',
        name: 'smartTools',
        component: () => import('../views/resourceCenter/smartTools.vue'),
        meta: {
          data: [
            { title: '资源中心', url: '/resourceCenter', requireAuth: true },
            { title: '智能工具', url: '/smartTools' },
          ],
          requireAuth: true,
        },
        children: [
          {
            path: '/card',
            name: 'card',
            component: () => import('../views/resourceCenter/card.vue'),
            meta: {
              data: [
                {
                  title: '资源中心',
                  url: '/resourceCenter',
                  requireAuth: true,
                },
                { title: '智能工具', url: '' },
              ],
              requireAuth: true,
            },
          },
          {
            path: '/pinyin',
            name: 'pinyin',
            component: () => import('../views/resourceCenter/pinyin.vue'),
            meta: {
              data: [
                {
                  title: '资源中心',
                  url: '/resourceCenter',
                  requireAuth: true,
                },
                { title: '智能工具', url: '' },
              ],
              requireAuth: true,
            },
          },
          {
            path: '/bihua',
            name: 'bihua',
            component: () => import('../views/resourceCenter/bihua.vue'),
            meta: {
              data: [
                {
                  title: '资源中心',
                  url: '/resourceCenter',
                  requireAuth: true,
                },
                { title: '智能工具', url: '' },
              ],
              requireAuth: true,
            },
          },
        ],
      },
      // 20210714 add
      {
        // 关于我们
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('../views/aboutUs/index.vue'),
      },
      {
        path: '/bannerDetail',
        name: 'bannerDetail',
        component: () => import('../views/homeComponents/bannerDetail.vue'),
      },
      {
        path: '/bannerAug',
        name: 'bannerAug',
        component: () => import('../views/homeComponents/bannerAug.vue'),
      },
      {
        path: '/zwgfTrain',
        name: 'zwgfTrain',
        component: () => import('../views/homeComponents/zwgfTrain.vue'),
      },
      {
        path: '/zjtVideoAudit',
        name: 'zjtVideoAudit',
        component: () => import('../views/homeComponents/zjtVideoAudit.vue'),
      },
      {
        // 新闻中心
        path: '/newsCenter',
        name: 'newsCenter',
        component: () => import('../views/newsCenter/index.vue'),
        meta: {
          data: [
            { title: '新闻中心', url: '/newsCenter', requireAuth: true },
            { title: 'HSK考试', url: '/HSK' },
          ],
          requireAuth: true,
        },
      },
      {
        // 新闻中心
        path: '/industryNews',
        name: 'industryNews',
        component: () => import('../views/newsCenter/industryNews.vue'),
        meta: {
          data: [
            { title: '新闻中心', url: '/newsCenter', requireAuth: true },
            { title: '行业动态', url: '/industryNews' },
          ],
          requireAuth: true,
        },
      },
      {
        path: '/editCard',
        name: 'editCard',
        component: () => import('../views/resourceCenter/editCard.vue'),
      },
      {
        path: '/saveCard',
        name: 'saveCard',
        component: () => import('../views/resourceCenter/saveCard.vue'),
      },
    ],
  },
  {
    // 登录
    path: '/login',
    name: 'login',
    component: () => import('../views/user/login.vue'),
  },
  {
    // 注册
    path: '/register',
    name: 'register',
    component: () => import('../views/user/register.vue'),
  },
  {
    // 忘记密码
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('../views/user/forgetPassword.vue'),
  },
  {
    // 路由重定向
    path: '/',
    redirect: '/home',
  },
];

const router = new VueRouter({
  mode: 'hash',
  routes,
});

// 添加这下面一段代码，就可以解决报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) {
//     if (localStorage.getItem('userInfo')) {
//       next()
//     } else {
//       next('/login')
//     }
//   } else {
//     next()
//   }
// })
