<template>
<a-config-provider :locale = "locale">
<div id="#app">
  <router-view></router-view>
</div>
</a-config-provider>
</template>

<script>
import cn from 'ant-design-vue/lib/locale-provider/zh_CN'
import en from 'ant-design-vue/lib/locale-provider/en_US'
import ja from 'ant-design-vue/lib/locale-provider/ja_JP'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
export default {
  name: 'App',
  components: {

  },
  data () {
    return {
      routeKsy: '',
      routeIndex: '',
      footer_show: true,
      style: {
        position: 'fixed',
        width: '100%',
        background: '#fff',
        borderBottom: 'none',
        boxShadow: '',
        zIndex: '99'
      },
      color: false,
      locale: cn
    }
  },
  watch: {
    $route: function (to) {
      // console.log('route',to)
      if (to) {
        this.routeKsy = to.name
        sessionStorage.setItem('routeKsy', to.name)
        // sessionStorage.setItem('routeIndex', 0)
        if (to.name === 'bookDetail' || to.name === 'bookRecommend' || to.name === 'PDF') {
          this.footer_show = false
        } else {
          this.footer_show = true
        }
      }
    },
    routeKsy: function () {
      this.routeKsy = sessionStorage.getItem('routeIndex')
    }
  },
  // 创建完毕状态
  created () {
    // 默认中文
    localStorage.getItem('lang') == undefined ? localStorage.setItem('lang', 'cn') : ''
    // 自己配置多语言适配
    this.$root.Bus.$on('switchLanguage', value => {
      switch (value) {
        case 'cn':
          localStorage.setItem('lang', 'cn')
          break
        case 'en':
          localStorage.setItem('lang', 'en')
          break
        case 'ja':
          localStorage.setItem('lang', 'ja')
          break
      }
      // 强制刷新页面
      this.$router.go(0)
    })
    // 系统组件适配
    const lang = localStorage.lang
    if (lang === 'cn') {
      this.locale = cn
      moment.locale('cn')
    } else if (lang === 'en') {
      this.locale = en
      moment.locale('en')
    } else if (lang === 'ja') {
      this.locale = ja
      moment.locale('ja')
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      // let offsetTop = document.querySelector(".header").offsetTop;
      // 设置背景颜色的透明读 0px 5px 3px #ccc
      if (scrollTop) {
        this.style.boxShadow = '0px 5px 3px rgba(204,204,204,.2)'
        this.color = true
      }
    },
    footer (bool) {
      this.footer_show = bool
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped lang="less">
.ant-layout-header,.ant-layout-content,.ant-layout-footer{
  margin: 0;padding: 0;
  background: #FFF;
}
.ant-layout-header {
  height: 64px;
  border-bottom:1px solid rgba(61,61,61,.15);
  box-sizing: border-box;
}
.ant-layout-content{
  // min-height: calc(100vh-'64px');
}
// .ant-layout-footer{
//   height: 360px;
//   background: #FFFFFF !important;
// }
//规定到屏幕最小宽度为 960px
// 小于960 会出现滚动条
#app{
  min-width:960px;
}
*{
  font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  // transform: translateX(30px);
  opacity: 0;
}
/deep/.ant-tabs-nav .ant-tabs-tab{
  font-size: 14px;
}
/deep/.ant-tabs-nav .ant-tabs-tab:hover{
  font-weight: 600;
  color: #333333;
}
/deep/.ant-tabs-nav .ant-tabs-tab-active{
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
/deep/.ant-tabs-ink-bar{
  height: 4px;
  border-radius: 2px;
}
</style>
