import Vue from 'vue'
import Vuex from 'vuex'
import animation from './animation'
import compose from './compose'
import contextmenu from './contextmenu'
import copy from './copy'
import event from './event'
import layer from './layer'
import snapshot from './snapshot'
import lock from './lock'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const data = {
    state: {
        ...animation.state,
        ...compose.state,
        ...contextmenu.state,
        ...copy.state,
        ...event.state,
        ...layer.state,
        ...snapshot.state,
        ...lock.state,
        userInfo: [], //个人信息
        userImg: '', // 个人头像
        buyMess: '', //购买信息
        payDataMess: '', //购买订单信息
        access_token: '',
        listData: [],
        editMode: 'edit', // 编辑器模式 edit preview
        canvasStyleData: { // 页面全局数据
            width: 600,
            height: 600,
            scale: 100
        },
        componentData: [], // 画布组件数据
        myComponentData: [], // 我的自定义数据
        curComponent: null,
        curComponentIndex: null,
        // 点击画布时是否点中组件，主要用于取消选中组件用。
        // 如果没点中组件，并且在画布空白处弹起鼠标，则取消当前组件的选中状态
        isClickComponent: false
    },
    plugins: [createPersistedState({
        storage: window.localStorage,
        reducer(val) {
            return {
                // 只储存state中的assessmentData
                userInfo: val.userInfo,
                userImg: val.userImg,
                access_token: val.access_token,
                listData: val.listData,
                buyMess: val.buyMess,
                payDataMess: val.payDataMess
            }
        }
    })],
    mutations: {
        ...animation.mutations,
        ...compose.mutations,
        ...contextmenu.mutations,
        ...copy.mutations,
        ...event.mutations,
        ...layer.mutations,
        ...snapshot.mutations,
        ...lock.mutations,
        setUserInfo(state, userInfo = []) {
            Vue.set(state, 'userInfo', userInfo)
        },
        setUserImg(state, img) {
            Vue.set(state, 'userImg', img)
        },
        setBuyMess(state, buyMess) {
            Vue.set(state, 'buyMess', buyMess)
        },
        setpayDataMess(state, payDataMess) {
            Vue.set(state, 'payDataMess', payDataMess)
        },
        setToken(state, token) {
            Vue.set(state, 'access_token', token)
        },
        setListData(state, userInfo = []) {
            Vue.set(state, 'listData', userInfo)
        },
        setClickComponentStatus(state, status) {
            state.isClickComponent = status
        },

        setEditMode(state, mode) {
            state.editMode = mode
        },

        setCanvasStyle(state, style) {
            state.canvasStyleData = style
        },

        setCurComponent(state, { component, index }) {
            state.curComponent = component
            state.curComponentIndex = index
        },

        setShapeStyle({ curComponent }, { top, left, width, height, rotate }) {
            if (top) curComponent.style.top = top
            if (left) curComponent.style.left = left
            if (width) curComponent.style.width = width
            if (height) curComponent.style.height = height
            if (rotate) curComponent.style.rotate = rotate
        },

        setShapeSingleStyle({ curComponent }, { key, value }) {
            curComponent.style[key] = value
        },

        setComponentData(state, componentData = []) {
            Vue.set(state, 'componentData', componentData)
        }, // myComponentData
        setMyComponentData(state, myComponentData = []) {
            Vue.set(state, 'myComponentData', myComponentData)
        },
        addComponent(state, { component, index }) {
            if (index !== undefined) {
                state.componentData.splice(index, 0, component)
            } else {
                state.componentData.push(component)
            }
        },

        deleteComponent(state, index) {
            if (index === undefined) {
                index = state.curComponentIndex
            }

            if (index == state.curComponentIndex) {
                state.curComponentIndex = null
                state.curComponent = null
            }

            state.componentData.splice(index, 1)
        }
    }
    // plugins: [createPersistedState()]
}

export default new Vuex.Store(data)