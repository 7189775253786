// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';
import antd, { message, Spin } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
// import axios from 'axios';
import myaxios from '@/api/index.js';
import ElementUI from 'element-ui';
import store from './store';
import '@/custom-component'; // 注册自定义组件
import '@/styles/animate.css';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/reset.css';
import '@/styles/common.css';
// 导入语言包
import i18n from '../public/languages/i18n';
Vue.use(antd);
Vue.use(ElementUI);
message.config({
  top: '100px',
  duration: 2,
  maxCount: 3,
});
Spin.setDefaultIndicator({
  indicator: (h) => {
    return '<a-icon type = "loading" / >';
  },
});
Vue.config.productionTip = false;
Vue.prototype.$fetch = (request, onError = () => {}, afterResponse) => {
  const userInfo = getUserInfo();
  // console.log('userInfo', userInfo)
  // request.headers = Object.assign({}, request.headers, {
  //   Authorization: userInfo && userInfo.token ? userInfo.token : null
  // })
  let classId = '';
  let bookId = request.body && request.body.bookId;
  if (!bookId) {
    bookId =
      shareState.state.bookId || localStorage.getItem('syzg-bookId') || 1;
  }
  if (
    userInfo &&
    userInfo.class &&
    userInfo.class.length &&
    userInfo.class[0].classId
  ) {
    classId = userInfo.class[0].classId;
  }
  request.body = Object.assign({}, request.body, {});

  return fetch(
    request,
    (err) => {
      if (err.response && err.response.status === 401) {
        router.currentRoute.name !== 'Login' &&
          router.push({
            name: 'Login',
          });
        Modal.destroyAll();
      } else onError(err);
    },
    (afterResponse) => {
      if (afterResponse.resultCode == 1200) {
        // message.info(afterResponse.resultMessage)
        router.push({
          name: 'MyBooks',
        });
      }
    }
  );
};
Vue.prototype.$message = message;
Vue.prototype.$axios = myaxios;
// 测试环境
// Vue.prototype.$resBasePath = 'http://192.168.6.111:8082/upload/res/path/';
// Vue.prototype.$resBaseVideo = 'http://192.168.6.111:8082/upload/res/spath1/'; // 视频
// Vue.prototype.$baseServer = 'http://192.168.6.111:6022/'; // 教师端
// Vue.prototype.$learnServer = 'http://192.168.6.111:8010/'; // 学生端
// Vue.prototype.$baseUrl = 'http://192.168.6.111:6021/';
// Vue.prototype.$uploadUrl = 'http://192.168.6.111:8082/upload/res/uploadCustom'; // 上传接口
// Vue.prototype.$videoAug = 'http://192.168.6.187:8080/';
// 生产环境
// Vue.prototype.$resBasePath = 'https://file.greatwallchinese.com/spath/'; // 非视频，如果为视频，则为spath1
// Vue.prototype.$resBaseVideo = 'https://file.greatwallchinese.com/spath1/'; // 视频
// Vue.prototype.$baseServer = 'https://auth.greatwallchinese.com/'; // 登录注册
// Vue.prototype.$learnServer = 'https://learn.greatwallchinese.com/'; // 学生端
// Vue.prototype.$baseUrl = 'https://www.greatwallchinese.com/';
// Vue.prototype.$uploadUrl =
//   'https://file.greatwallchinese.com/upload/res/uploadCustom'; // 上传接口
// Vue.prototype.$videoAug = 'https://edu.greatwallchinese.com/';

Vue.prototype.$resBasePath = process.env.VUE_APP_ResBasePath;
Vue.prototype.$resBaseVideo = process.env.VUE_APP_ResBaseVideo; // 视频
Vue.prototype.$baseServer = process.env.VUE_APP_BaseServer; // 教师端
Vue.prototype.$learnServer = process.env.VUE_APP_LearnServer; // 学生端
Vue.prototype.$baseUrl = process.env.VUE_APP_BaseUrl;
Vue.prototype.$uploadUrl = process.env.VUE_APP_UploadUrl; // 上传接口
Vue.prototype.$videoAug = process.env.VUE_APP_VideoAug;

//智慧云平台学生端网页跳转地址
Vue.prototype.$studentSkipUrl = process.env.VUE_APP_studentSkipUrl;
//智慧云平台教师端网页跳转地址
Vue.prototype.$teacherSkipUrl = process.env.VUE_APP_teacherSkipUrl;

/* eslint-disable no-new */
new Vue({
  render: (h) => h(App),
  router,
  store,
  i18n,
  data: {
    // 空的实例放到根组件下，所有的子组件都能调用
    Bus: new Vue(),
  },
}).$mount('#app');
