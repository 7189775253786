import axios from 'axios';
import qs from 'qs';
import { message } from 'ant-design-vue';
import router from '@/router/index.js';
import store from '@/store/index.js';

// 1.创建实例
const instance = axios.create({
  baseURL: process.env.VUE_APP_BaseUrl,
  timeout: 30000, // 响应时间
});

// 配置cookie
// axios.defaults.withCredentials = true
// 配置请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 静态资源
// Vue.prototype.$static = ''
// 配置接口地址
// POST传参序列化(添加请求拦截器)
instance.interceptors.request.use(
  (config) => {
    if (config.method === 'post') {
      config.data = qs.parse({ ...config.data }); // 此处为修改参数类型 parse为json格式 stringify为form格式
    } else {
      config.params = { ...config.params };
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    return config;
  },
  (err) => {
    message.error('请求错误');
    return Promise.reject(err);
  }
);
// 返回状态判断(添加响应拦截器)
instance.interceptors.response.use(
  (res) => {
    const { data } = res;
    // console.log(res);
    if (data && (data.resultCode == 9 || data.resultMessage == '无效token')) {
      // globalStore.setToken('');
      store.commit('setToken', '');
      router.push({ name: 'login' });
      return Promise.reject(res);
    }

    return res;
  },
  (err) => {
    message.error('请求失败，请稍后再试');
    return Promise.reject(err);
  }
);

export default instance;
